<template>
  <div>
    <h2>
      User Preferences
      <el-button
        type="primary"
        style="float:right;"
        @click="savePrefs"
      >
        <i class="far fa-save" />
        Save Preferences
      </el-button>
    </h2>

    <el-row :gutter="12">
      <el-col :md="12">
        <el-card>
          <div slot="header">
            <span>Options and Defaults</span>
          </div>
          <el-form>
            <el-form-item>
              <el-checkbox v-model="form.options.auto_dedupe">
                Automatically deduplicate names
              </el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.options.disable_attrib_warn">
                Don't warn about attribution being billable
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Pagination Size">
              <el-select v-model="form.options.pagination_size">
                <el-option
                  v-for="item of pageSizes"
                  :key="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :md="12">
        <el-card>
          <div slot="header">
            <span>Notifications</span>
          </div>
          <el-table
            :data="notificationEvents"
          >
            <el-table-column
              prop="label"
              label="Event"
            />
            <el-table-column
              label="Email"
              align="center"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="form.notification[scope.row.event].email" />
              </template>
            </el-table-column>
            <!-- For future use -->
            <el-table-column
              v-if="smsNotificationsAllowed"
              label="SMS"
              align="center"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="form.notification[scope.row.event].sms" />
              </template>
            </el-table-column>
            <el-table-column
              v-if="slackNotificationsAllowed"
              label="Slack"
              align="center"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="form.notification[scope.row.event].slack" />
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-card v-if="this.slackIsDefined">
      <div slot="header">
        <span>Slack Member ID</span>
        <help-tip style="margin-left: 0.5em">
          Slack Member ID can be found by clicking to view user's full profile,
          Select More, and then Copy member ID
        </help-tip>
      </div>
      <el-input v-model="form.mentions" />
    </el-card>
  </div>
</template>

<script>
import { user, system } from '@/adonis-api';
import { mapGetters, mapState } from 'vuex';
import HelpTip from '../../global/HelpTip.vue';

export default {
  name: 'PageSettingsPrefs',
  components: { HelpTip },

  data() {
    return {
      notificationEvents: [],
      form: {
        options: {},
        notification: {},
        mentions: '',
      },
    };
  },

  computed: {
    ...mapGetters('user', ['preferences', 'userId', 'slackIsDefined']),
    ...mapState('settings', ['options']),

    pageSizes() {
      return (this.options) ? this.options['pagination:pageSizes'] : [];
    },

    // For the future...
    smsNotificationsAllowed() {
      return false;
    },
    slackNotificationsAllowed() {
      return true;
    },
  },

  watch: {
    preferences(val) {
      this.form = Object.assign({}, val);
    },
  },

  async mounted() {
    this.form = Object.assign({}, this.preferences);
    this.notificationEvents = await system.getNotificationEventList();
  },

  methods: {
    async savePrefs() {
      try {
        await user.update(this.userId, { preferences: this.form });
        this.$notify.success({
          message: 'Your preferences have been saved.',
        });
      } catch (e) {
        this.$reportError(e);
      }
    },
  },
};
</script>

<style>

</style>
